@font-face {
  font-weight: 400;
  font-family: Circular;
  src: url(/static/fonts/circular-book.woff2);
  font-display: swap;
}

@font-face {
  font-weight: 500;
  font-family: Circular;
  src: url(/static/fonts/circular-medium.woff2);
  font-display: swap;
}

@font-face {
  font-weight: 700;
  font-family: Circular;
  src: url(/static/fonts/circular-bold.woff2);
  font-display: swap;
}
